import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: block;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  background-color: #f4f3f4;
`;

export const Content = styled.div`
  width: calc(100vw - 80px);
  margin-left: 82px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    margin-left: 0;
  }
`;

export const PageTitle = styled.div`
  margin: 180px 0 30px;
  padding-left: 52px;
  h1 {
    transform: translateX(-20px);
    font-size: 150px;
    margin-bottom: 0;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    margin: 60px 0 30px;
    h1 {
      font-size: 100px;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    margin: 0 auto 20px;
    padding-left: 0;
    background-color: black;
    h1 {
      width: 90%;
      margin: 0 auto;
      padding-top: 80px;
      color: white;
      transform: none;
      font-size: 40px;
      transform: translateY(6px);
    }
  }
`;
