import React from 'react';

import { Footer, Header, Navigation, MobileNav } from 'components';

import { Container, Content, PageTitle } from './styled';

const IndexLayout = ({
  children,
  title,
  navColor,
  backgroundColor,
  headerColor,
}) => {
  return (
    <Container style={{ backgroundColor: backgroundColor }}>
      <Navigation colorScheme={navColor} />
      <Content>
        <Header color={headerColor} />
        {title && (
          <PageTitle>
            <h1>{title}</h1>
          </PageTitle>
        )}
        {children}
        <Footer />
      </Content>
      <MobileNav />
    </Container>
  );
};

export default IndexLayout;
