import React from 'react';
import { graphql } from 'gatsby';
import { InnerWrap } from 'components/common/Containers/styled';

import IndexLayout from 'layouts/IndexLayout';
import { Guides, SEO } from 'components';
import { DarkScheme } from 'helpers/navColors.js';

const GuidesIndex = ({ data }) => {
  const edges = data.allStrapiGenreGuides;

  return (
    <IndexLayout title='Guides' navColor={DarkScheme}>
      <SEO title='Genre guides to Japanese music' />
      <InnerWrap>
        <Guides {...edges} />
      </InnerWrap>
    </IndexLayout>
  );
};

export const query = graphql`
  query {
    allStrapiGenreGuides(sort: { fields: publishedDate, order: DESC }) {
      edges {
        node {
          title
          publishedDate(formatString: "MMMM Do YYYY")
          slug
          coverImage {
            url
          }
          albumRecommendations {
            albumName
            artistName
            coverImage {
              url
            }
          }
        }
      }
    }
  }
`;

export default GuidesIndex;
